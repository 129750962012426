import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import itemsData from "./inscript";
import banner from "./banner.png";
import logo from "./logo.png";
import dogeItems from "./dogeinscrip";

function Gallery() {
  const [showDoginals, setDoginals] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // State to toggle switch

  const handleToggle = () => {
    setIsChecked(!isChecked); // Toggle the state
  };

  const handleGridSwitch = () => {
    setDoginals(!showDoginals); // Toggle the state to switch between grids
  };

  return (
    <div
      className="App"
      style={{
        background: "linear-gradient(90deg, rgb(88 59 141), rgb(192 59 95))",
      }}
    >

      <div
        style={{
          marginTop: "0px",
          background: "linear-gradient(90deg, rgb(88 59 141), rgb(192 59 95))",
        }}
      >
        <div className="container">
          <img src={banner} className="banner"></img>
          <section id="collection" style={{ padding: "10vh 0px" }}>
            <div className="container">
              <div
                className="pageHeader d-flex flex-column justify-content-center align-items-center pb-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-duration={800}
                data-aos-once="true"
              >
                <div className="text1">
                  <h1 style={{ fontSize: "2rem" }}>
                    Waifus Genesis Collection
                  </h1>
                </div>
                <table
                  className="text1"
                  style={{ borderSpacing: 15, borderCollapse: "separate" }}
                >
                  <tbody>
                    <tr>
                      <td>--</td>
                      <td>Ordinals</td>
                      <td>150k to 155k</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>--</td>
                      <td>Doginals</td>
                      <td>Sub 100K</td>
                      <td>--</td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ width: "50px", height: "25px" }}></div>

                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showDoginals && isChecked}
                    onChange={() => {
                      handleGridSwitch(); // Call handleGridSwitch function
                      handleToggle(); // Call handleToggle function
                    }}
                  />
                  <span className="slider round">
                    <span className="inner-text">
                      {isChecked ? "Doginals" : "Ordinals"}
                    </span>
                  </span>
                </label>

                <div style={{ width: "100px", height: "50px" }}></div>

                <div
                  id="collectionItems"
                  className="row g-3 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration={800}
                  data-aos-once="true"
                >
                  {!showDoginals &&
                    itemsData.map((item, index) => (
                      <div className="">
                        <div className="item wrapper" title={item.name}>
                          <div className="item-inner text-center">
                            <div
                              className="item-thumb"
                              style={{ marginBottom: 10 }}
                            >
                              <a
                                href={`https://ordinals.com/inscription/${item.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="img-pixel"
                                  alt="Waifu"
                                  src={`https://cdn.ordinalswallet.com/inscription/preview/${item.id}`}
                                />
                                {/**/}
                              </a>
                            </div>
                            <div className="text1">
                              <h6>
                                <a style={{ color: "white" }} target="_blank">
                                  {1 + index}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {showDoginals &&
                    dogeItems.map((item, index) => (
                      <div className="">
                        <div
                          className="item wrapper"
                          title={`Doginal Waifus ${index}`}
                        >
                          <div className="item-inner text-center">
                            <div
                              className="item-thumb"
                              style={{ marginBottom: 10 }}
                            >
                              <a
                                href={`https://wonky-ord.dogeord.io/inscription/${item.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="img-pixel"
                                  alt="Waifu"
                                  src={`https://wonky-ord.dogeord.io/content/${item.id}`}
                                />
                                {/**/}
                              </a>
                            </div>
                            <div className="text1">
                              <h6>
                                <a style={{ color: "white" }} target="_blank">
                                  {1 + index}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <footer className="footer">
          <div className="footer-content">
            <p>© 2023 OrdinalWaifus #Waifu4Laifu </p>
            <p>Twitter: <a href="https://twitter.com/OrdinalWaifuNFT">OrdinalWaifuNft</a></p>
          </div>
        </footer>
    </div>
  );
}

export default Gallery;
