const itemsData = [
  {
    id: "ecd558ad6ab5a9593ce2fd5ac2274fd2eaba9745f1ad57e9f3dc4a5c5c024986i0",
    name: "Ordinal Waifu #1",
  },
  {
    id: "5b6a67861fd817fe2645ce874b478d6f15526b32c3b39611053f1d484b69fc8ci0",
    name: "Ordinal Waifu #2",
  },
  {
    id: "dc359553ffdbff8a33d23511889e873ad528ba795a4b0d3fc215cb34d49bedd3i0",
    name: "Ordinal Waifu #3",
  },
  {
    id: "a6cecea7d531ced7b2a03eb279ed84f6c4a0a92a6316a006933f246b796f6e0di0",
    name: "Ordinal Waifu #4",
  },
  {
    id: "cbb1251209d7e7948ffbd83297dff8581490a2b7d1114b7de15e175d2e2207f0i0",
    name: "Ordinal Waifu #5",
  },
  {
    id: "b749fb1d24e1fad1201dc689c139911fa04f61637c85672d587bfa7c8e203dabi0",
    name: "Ordinal Waifu #6",
  },
  {
    id: "1332d857bb451b574f3c5c3a5939c98901e22f670555dfb7d21d8fa41c59f6bdi0",
    name: "Ordinal Waifu #7",
  },
  {
    id: "e8173f2d61e4bcbb8d8b3f36a97ce2a360df1049fd4535dc72263e425999cce0i0",
    name: "Ordinal Waifu #8",
  },
  {
    id: "2ba68396ffdbacd520fa5e0098892a2aa88060e00d23573ee1f2537a1589bb2bi0",
    name: "Ordinal Waifu #9",
  },
  {
    id: "2f9ff681ab6b7189d053d29eb9ade8116aff28ce0bc272796c81b7815f659bd5i0",
    name: "Ordinal Waifu #10",
  },
  {
    id: "f2de434cc6ae18fe7e9d4800816b09df078ddd05c5ec60b9242f77b62337d5f2i0",
    name: "Ordinal Waifu #11",
  },
  {
    id: "0cf8df7ed825a7fd09e0f2307bf6c8f811dc72328d6c8a4bcfda0dc958e99683i0",
    name: "Ordinal Waifu #12",
  },
  {
    id: "5cb48ad496c6405e803c5fedbf35e4bba9ce94e9d3066e96e572e4381331bd67i0",
    name: "Ordinal Waifu #13",
  },
  {
    id: "0635966df111ced8322c0357d6f610bd2f35d0f3f2283889d32fa2b214b03dd6i0",
    name: "Ordinal Waifu #14",
  },
  {
    id: "c4bbbe817ca77b3644db2384c8b071a63a64acf30547800b25caad6e01fcbf62i0",
    name: "Ordinal Waifu #15",
  },
  {
    id: "8bca59bc0a80178282872182e6ff26eccfb5fc46fadc11f4c2b8e41915d733dbi0",
    name: "Ordinal Waifu #16",
  },
  {
    id: "75342c8082a0f8c98b138cdc145ec27d8ddbceeb222f71f9bba59f58deb268b6i0",
    name: "Ordinal Waifu #17",
  },
  {
    id: "63632b027d5fdb871aa693c761fa830afa51bd331963d0b158d5078049e18a91i0",
    name: "Ordinal Waifu #18",
  },
  {
    id: "274e36d08d4ecf57188ffafaab764fe8c505d5229adae57feba11858976af3d5i0",
    name: "Ordinal Waifu #19",
  },
  {
    id: "e1f85cdb321b7c938e7514f7fcc89ef2634e4c1ff86b4ebc0633d551b3e5db78i0",
    name: "Ordinal Waifu #20",
  },
  {
    id: "25e2e3dfe5240626127eeaf367a31354c6b29eae1ba34c4d80eac5a8c1ac3337i0",
    name: "Ordinal Waifu #21",
  },
  {
    id: "e7804653eafde55358c08637e5ba45644b2d05cd242449dfe232af3f96a256f9i0",
    name: "Ordinal Waifu #22",
  },
  {
    id: "78f67c528b45a39eafda768ef373dbeb419e3b976e056f4614001315b161f753i0",
    name: "Ordinal Waifu #23",
  },
  {
    id: "0ab5fbd9f9486d8b40e7024a7346054af3c9104cb1a57ba3616aa83b8b76c602i0",
    name: "Ordinal Waifu #24",
  },
  {
    id: "5c4f0cce3782d13c9dafd06b0047fca50c5f20b1f0e28f6406ad77392c9a6230i0",
    name: "Ordinal Waifu #25",
  },
  {
    id: "3b3ae371761b9f4103817ca9567811e8f40d637f1de6efe4e64e1e73e9d146d8i0",
    name: "Ordinal Waifu #26",
  },
  {
    id: "6405282fbcc7f79412b4751b88801f097a94cf2ab59c5f49e65b32615dcdc8abi0",
    name: "Ordinal Waifu #27",
  },
  {
    id: "9b5f46491e59bbc4c220f2e5f2579d865c39e350994fcf76c7fcc4bf8667a1f6i0",
    name: "Ordinal Waifu #28",
  },
  {
    id: "fc85059cc4de4b7f02c75cb339ebd938a3743a4ebacd904216704900519f657ai0",
    name: "Ordinal Waifu #29",
  },
  {
    id: "e99e3fed3539f44bcd9bff9e2522bd243a2950f29f42c7b89bfa2c10dd43f8d3i0",
    name: "Ordinal Waifu #30",
  },
  {
    id: "39de7cf3e5ecf2626421d088ab6054324eff62a508f0ce7b8e1e6710c843ac10i0",
    name: "Ordinal Waifu #31",
  },
  {
    id: "13329c3beb6107ae58288cae99efee8f7553d3b7a73a1e517730443f666855fei0",
    name: "Ordinal Waifu #32",
  },
  {
    id: "691a93d8ec20eb794d23f550d0d568550e0415d91280ece7bf236dcae34af321i0",
    name: "Ordinal Waifu #33",
  },
  {
    id: "7669e19ef52f05961f7a8d2bd251a6f093768f203efe3ca58234a6bb00a10c62i0",
    name: "Ordinal Waifu #34",
  },
  {
    id: "decb77bcb82f593db17146c03976aaecead9b0bb7579678040f02545dd23058fi0",
    name: "Ordinal Waifu #35",
  },
  {
    id: "f90419548919e65873c849528cb04c7155516207003c9bd9d6ffd87dd22084cdi0",
    name: "Ordinal Waifu #36",
  },
  {
    id: "2dfe5459ff188013adda7019a0041283b5c3a30c02dc7fd66f0549b56c0f0c11i0",
    name: "Ordinal Waifu #37",
  },
  {
    id: "5bfb03689180fc120a370a6f7a77f66fffa4970c4f656f18b5e799281628ebaei0",
    name: "Ordinal Waifu #38",
  },
  {
    id: "9777df26873662601ab4e4562b903ea9794a309994575bff3dbcc820b1e0640bi0",
    name: "Ordinal Waifu #39",
  },
  {
    id: "16c6c0df88f072be6f3fed31797963a6d1a6b0ac53d1d13c5831796e6362000ai0",
    name: "Ordinal Waifu #40",
  },
  {
    id: "22cc640ec0c2de554ed3c92ec5842b78f543ca7157aa074c12fde81b818a2d8fi0",
    name: "Ordinal Waifu #41",
  },
  {
    id: "8d1761560214838a7265eaa835189a8489f9b29aed213dd6582dfc64c0a94b4di0",
    name: "Ordinal Waifu #42",
  },
  {
    id: "4ebfd111ea3a4b21d37b7603b50906b98a11f144f4c2cd95a9ee97fb624cb961i0",
    name: "Ordinal Waifu #43",
  },
  {
    id: "ad8ba1ff159633f022153491e83b057bf219db4aaa192eb5bdcc1e6cad4862e7i0",
    name: "Ordinal Waifu #44",
  },
  {
    id: "ecb2891d1cb195cfe49f7acd26404ff58ccebf3d0281e2252ea0b51428fc1b8ci0",
    name: "Ordinal Waifu #45",
  },
  {
    id: "3a6162ee903e2fa18f27942c90c34f1477e5737095fc677981289cb06f159f21i0",
    name: "Ordinal Waifu #46",
  },
  {
    id: "07a5891c0c307c67da7d54b4d5a1b3c029762f99ba476ebe4fe976eed214265fi0",
    name: "Ordinal Waifu #47",
  },
  {
    id: "7eff606d7acb0361a41d253f38097799a229770e994c1c914dc537735fb948d2i0",
    name: "Ordinal Waifu #48",
  },
  {
    id: "58704c5371796a7c4518ec5d81430cf957d3e9ddce469077ee29187a97e9351bi0",
    name: "Ordinal Waifu #49",
  },
  {
    id: "4946776b9b1f645bb43d10e07c221d9bf8652e441563b2e02e279b7aca684760i0",
    name: "Ordinal Waifu #50",
  },
  {
    id: "4567de8a84a7c02105a57d85dd056b35d61abdc7ec6a5c9cfa238041dc4f64eci0",
    name: "Ordinal Waifu #51",
  },
  {
    id: "5550abd29a89e1bfb254f11041cf255b07aea948bb142dacf123cb0918d58dc9i0",
    name: "Ordinal Waifu #52",
  },
  {
    id: "348cbf68adf6c90325c529e4ba096d860924d1d6b7bc8e7fad8bf654fa9ab695i0",
    name: "Ordinal Waifu #53",
  },
  {
    id: "abcbdea6ad8072ad20982a5a5514aa5fcc01b90db361ebe64dc461106c737432i0",
    name: "Ordinal Waifu #54",
  },
  {
    id: "c92cc8c8c0110f3e9d0d8656ad05662f704008ae04560b9e59c0321733e19225i0",
    name: "Ordinal Waifu #55",
  },
  {
    id: "8bf56561d205c6604fff7ce82d5fb5837cfd4089f4b24816ca2af4f8bcdbba93i0",
    name: "Ordinal Waifu #56",
  },
  {
    id: "db9e7cad77ce221e641e3c38b4b149f512eb088c6b6992a86d33425af6348658i0",
    name: "Ordinal Waifu #57",
  },
  {
    id: "e4428a687bde06a3cf95ef3945c3fe742a341c010b0a732a1f8bd7aadb00dc04i0",
    name: "Ordinal Waifu #58",
  },
  {
    id: "0647c109d09c2f4093d202aaf85e39377ce7e3e9c0c00b2ff795a70a2044ce78i0",
    name: "Ordinal Waifu #59",
  },
  {
    id: "fb76a29dcc177fa63db9d58cbae17e96fb302e6ee05b048746511368314f7350i0",
    name: "Ordinal Waifu #60",
  },
  {
    id: "3d0155814de72fb8d81968f5e9ca4205d4636d78a18b903788c529a6f15afdc2i0",
    name: "Ordinal Waifu #61",
  },
  {
    id: "3612d43cd30d520520b90ca7ecaf717602ed58297aedb460ed77ce0ad8f6ad7di0",
    name: "Ordinal Waifu #62",
  },
  {
    id: "790df6fe7da5a2756ba5775d7688bcd73ffbdcf47aee047c47a56ef6810e5f08i0",
    name: "Ordinal Waifu #63",
  },
  {
    id: "d9a86e91503bed34eb6f24c9c091e5c404cf77e72f06e0d5542da1f96f4ffca5i0",
    name: "Ordinal Waifu #64",
  },
  {
    id: "6a24ee420eda43dcd14bed52dd2866007ef2538c425398a94b65a4a594344ad5i0",
    name: "Ordinal Waifu #65",
  },
  {
    id: "b18b872179cf36a76e4f00ba588f2ef90b9ae2ed4c03b735ce182c3413a71b83i0",
    name: "Ordinal Waifu #66",
  },
  {
    id: "7274ba6ff08badd8c78e8172c457f129febc6435c6e739367c48b288d375de8ci0",
    name: "Ordinal Waifu #67",
  },
  {
    id: "ddbd33e498fa38f808e6b0f8a384641e412c72a51ed8e9af9b7a6f6bdaa73da9i0",
    name: "Ordinal Waifu #68",
  },
  {
    id: "add10f480626bbd7ede0d4979faabda254e741bd2f4784615c64cbc3e64318f2i0",
    name: "Ordinal Waifu #69",
  },
  {
    id: "f73a2876d84ae9e8a6ae55a5aa60769b14602e3ab2f02c9760dcf7cfa24cb97ci0",
    name: "Ordinal Waifu #70",
  },
  {
    id: "7cc056eece800b1dc775ffb54699e1b35466068a1a77fd295df0f0354d91f096i0",
    name: "Ordinal Waifu #71",
  },
  {
    id: "ab4b1224a08bc9c82a686dba8fb281fd83b8d9beadf5fc1ac1a1644cf37dbe9ei0",
    name: "Ordinal Waifu #72",
  },
  {
    id: "8c8b13cf6fe8543140cf2544db46956453a725904f6515295243cc4c6a03c6bbi0",
    name: "Ordinal Waifu #73",
  },
  {
    id: "cd672e90fa18701b153496ac26698e64935fbdfe7d79fe8a7c81b0731b64d692i0",
    name: "Ordinal Waifu #74",
  },
  {
    id: "5af09afe83f80962b14fc8eb21f3a5973c1143c60fe889263093d622444eeab5i0",
    name: "Ordinal Waifu #75",
  },
  {
    id: "7fd4fb208893b3930189cf4bd2c94850842d5cad82a69a3d5f736b9c71995051i0",
    name: "Ordinal Waifu #76",
  },
  {
    id: "57ff330ebe7c6f3511e7fc2bd746d7a013e8239f8f22e35407efaf78999890e3i0",
    name: "Ordinal Waifu #77",
  },
  {
    id: "96d08074481b3ed572b410d7f7af136c8877c96975e554f0f6fbc1320998aeaci0",
    name: "Ordinal Waifu #78",
  },
  {
    id: "62c7a0a21240ad124967dbee7bd7e20e3de3561911e6b658d07ebdfd9d06a748i0",
    name: "Ordinal Waifu #79",
  },
  {
    id: "434a1d128e49e7b11eb516394b1a26a1113baf6a0ca48ce40dbe2c8d94c13fdbi0",
    name: "Ordinal Waifu #80",
  },
  {
    id: "8ccced7415b4e7519de073059caf69dbf293f266f2a553150453fe356fec7229i0",
    name: "Ordinal Waifu #81",
  },
  {
    id: "e761c05f533d774f521d9dfb12a914cbb1dcae8ab11f7599871ca520974790edi0",
    name: "Ordinal Waifu #82",
  },
  {
    id: "871f4647b4102ef35cf5f3edc5f55ec9f11abc31f914153ee5358ab8a3ce5caci0",
    name: "Ordinal Waifu #83",
  },
  {
    id: "f3798ca2b4a0906a9552b5f05d5e0f26c20d5ab63a258dacd63e74f2b140a5d9i0",
    name: "Ordinal Waifu #84",
  },
  {
    id: "fc98714b78babe64200b1b4c7217580aa56131e2f815fcd2401d740cc00c887fi0",
    name: "Ordinal Waifu #85",
  },
  {
    id: "8b57150512e3e12c4aa8c72f6d423cf295b4330ef7939490a66581a0a8a091c5i0",
    name: "Ordinal Waifu #86",
  },
  {
    id: "eea2696c927b11feab5f10bc1f4363208a49c1f8baddd46944b239eff61ee299i0",
    name: "Ordinal Waifu #87",
  },
  {
    id: "cca2b2ebf27a75084fdaf9c914018cad21984726a07fa0e11945de57e2c6ec78i0",
    name: "Ordinal Waifu #88",
  },
  {
    id: "dce156aa9beb32420b25ea63785c8d091bc611f900d15dd29deecd51ad311c54i0",
    name: "Ordinal Waifu #89",
  },
  {
    id: "ad4b455bd735797712815b25b6ce5b40d2f2b48ba08bdc121eb8ffb497987fc5i0",
    name: "Ordinal Waifu #90",
  },
  {
    id: "39f081b9950ea7fab70143c3dcbc4d01c45edc020316a146463b3b228d043467i0",
    name: "Ordinal Waifu #91",
  },
  {
    id: "3398bc1aac4645c9a047969a1803dbee13618104c070fec2a275b6549b239ef6i0",
    name: "Ordinal Waifu #92",
  },
  {
    id: "d124bb6dc1aca4b6bc348018779fa090af7cb2e12fffdf21865ce85f7c22cb79i0",
    name: "Ordinal Waifu #93",
  },
  {
    id: "a62c6de44125af446a7087284b8ac7a0dcb232b1e5c5acefd51ab8ccc084494ei0",
    name: "Ordinal Waifu #94",
  },
  {
    id: "384ca7fa921adc51af8238228bc8d52bd63eb611c0f89af9071674e20bbbeedei0",
    name: "Ordinal Waifu #95",
  },
  {
    id: "9faa04d1a669a620c0ad3651000ecf42b815145bc690aa63a11996962e9cfadfi0",
    name: "Ordinal Waifu #96",
  },
  {
    id: "a90715f122bed39dc8ccfac4c901934b6759b173fcf554ec79ed012d24b14cc5i0",
    name: "Ordinal Waifu #97",
  },
  {
    id: "e7fae025451cdb1e3381031aab86e30585a534d686fd86da900e79963af6fbcai0",
    name: "Ordinal Waifu #98",
  },
  {
    id: "cbdb0a40f34821f987652aaaf28c807a9bb67ef1a194dcb30b398e7ecd7c24d0i0",
    name: "Ordinal Waifu #99",
  },
  {
    id: "c53dc1693270aea48f7fbefcac9a78aa8ebd346ad529d17ecbc8e04468042d01i0",
    name: "Ordinal Waifu #100",
  },
];

export default itemsData;
