import "./whitelist.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import banner from "./banner.webp";
import logo from "./logo.png";
import { useState } from "react";
import waifuGIF from "./waifu.gif";
import waifu from "./Ordinal.gif";
import addresses from "./addresses";

function Home() {
  const [whitelistStatus, setWhitelistStatus] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [textColorClass, setTextColorClass] = useState("");

  const ordzarAddresses = [
    "bc1pxr29mag5zr8247sfqtf4rycvh3aqmcvr2l4nnu4cvm5f56pmv44szlxsxg",
    "bc1pn9zy48h5q5yumnjtpd5l4erzyg6k0p2325zffx7uqp73a40qzjpq5yn2jw",
    "bc1pceu7r2t6j5293jf0swqll9at00289nguh3gh8rmcw37qtcz0hwysc2fxe4",
    "bc1p42cd2t3ncfayztv6ucrl6zmm2rgm6us3rsagpyzms2tc246yws7se226x6",
    "bc1pqwu5rp3s82xwcyqvwjc8skw463k9htl42ag6war6m0qnmfjxl24sxg508g",
    "bc1p7gxlfm8yxwgz3scg2kumg3v2p6jhh8uxsnn58xzku6vj9nnutr6qnf88az",
    "bc1pdyurwegkjqvs5kucun30vhj0qdq8n4lu2k8uezmrjuefsypcmy5qnfhssy",
    "bc1pgkyr3dgqh2xzjhpdf9fxry2x0fq9zeqdhyvqn2n2a07qnh532qss9yxkzw",
    "bc1pxgjg0mmhp39kc5789h5hkuyawqgcqcn77a7shvkefgw6teupr56qmntg3r",
    "bc1plpkzfn4m0p3jl4f6zgwgv67dnm2pz7vh23heajmqsqx5nx6dxzdqqpkll2",
    "bc1pjqc8lk0txq59mvkwlfh0rzzkhydtlln5gfh9qz9tk50ulrnqjplss7jfhk",
    "bc1pk40pm6avclmesjwvepjk97twdwj9wrv7kzkn9ealc687ma0jrplsz63pqr",
    "bc1phac696fxzw6rrk0az2h8gxc37tk726elfr53n7a0737xl20fz2kqsdmxmg",
    "bc1ptsrx7d79swy6u7ucx8yd6u8dgy7kz7cy6x4hyk0vpx3220fp9dwq3l2wua",
    "bc1p6k8u9xcwjvd92pq37j5ylxgkj5dv88wfzdgc9vsv76w2ncd4emlq93dvxw",
    "bc1p058g6ruu2s3kwsnqjqhjgmekyafwpl6quxml8s5plxdyda83z30qjjn4t7",
    "bc1p42n3e4u7nv5x42zdrrlvn5zk56f7k3zfawzv6fac92uglvesfv0s0hsrpv",
    "bc1pawtxsl3w9wytzk562vgytxsazu8crm5ryavsyuuccpg5emfrmsysr2qxsn",
  ];


  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update the input value in state
  };

  const checkWhitelist = () => {
    const address = inputValue.toLowerCase();
    if (addresses.includes(address)) {
      setTextColorClass("green-text");
      setWhitelistStatus("You're Whitelisted for the Sale");
    } else if(ordzarAddresses.includes(address)){
      setTextColorClass("green-text");
      setWhitelistStatus("Hola! Ordzaar Team, Already Whitelisted.");
    }
    else {
      setTextColorClass("red-text");
      setWhitelistStatus("You're not Whitelisted for the Sale ");
    }
  };

  return (
    <>
      <div
        className="App"
        style={{
          background: "linear-gradient(90deg, rgb(88 59 141), rgb(192 59 95))",
        }}
      >
        <div className="banner-container">
          <img src={banner} alt="banner" className="banner-main" />
        </div>

        <div className="about">
          <img src={waifuGIF} alt="" className="centered-gif" />
          <div className="about-text">
            <p className="bold-text">
              The Ordinal Waifu collection featuring 10,000 NFTs, is on a
              mission to spread love and forge a strong community in Ordinal
              Ecosytem. Each Waifu is a unique blend of exquisite art and a
              symbol of connection, bringing collectors together in a vibrant
              and engaging digital space. With the motto of "WAIFU4LAIFU" these
              ORDINALS are more than just collectables; they're the building
              blocks of a supportive network, weaving a tapestry of friendship
              and shared stories in this Space. This collection is not only an
              artistic endeavour but also a commitment to fostering a sense of
              belonging and warmth in the digital realm.
            </p>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="gif-container">
          <img
            src={waifu}
            alt="Your GIF"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
        <div className="whitelist-check">
          <span className="text-center">-- Waifulist Checker --</span>
          <div className="whitelist-input">
            <input
              type="text"
              id="whitelist-input"
              placeholder="Enter your wallet address"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <div className="button-container">
            <button className="pixelated-btn" onClick={checkWhitelist}>
              Check
            </button>
          </div>
          <div className="spacer"></div>
          <div
            className={`text-center bold-text red-text ${textColorClass}`}
            id="whitelist-status"
          >
            {whitelistStatus}
          </div>
        </div>

        <footer className="footer">
          <div className="footer-content">
            <p>© 2023 OrdinalWaifus #Waifu4Laifu </p>
            <p>
              Twitter:{" "}
              <a href="https://twitter.com/OrdinalWaifuNFT">OrdinalWaifuNft</a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Home;
