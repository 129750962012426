import logo from "./logo.png";
import { useState } from "react";
import "./whitelist.css";
import { Link } from "react-router-dom";
import './App.css'
import laifu from './laifu.gif'


function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="navbar-wrapper sticky-top">
      <nav className="navbar-top sticky-top">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <img src={laifu} alt="Logo" />
        <div
          className={showMenu ? "hamburger" : "hamburger active"}
          onClick={toggleMenu}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="menu" style={{ left: showMenu ? "-100%" : "0px" }}>
          <ul>
            <li>
              <Link to="/" className="nav-button">
                Home
              </Link>
            </li>
            <li>
              <Link to="/gallery" className="nav-button">
                Gallery
              </Link>
            </li>
            <li>
              <a  className='nav-button' target="_blank" href="https://discord.gg/ordinalwaifus" rel="noreferrer">
                Discord
              </a>
            </li>

            <li>
              <a className="nav-button" target="_blank" href="https://twitter.com/OrdinalWaifuNFT" rel="noreferrer">
                Twitter
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
