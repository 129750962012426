const dogeItems = [
  {
    id: "d26310466eb0640123c04bc1749cff74e6dce1b5a1159f125dc88fadefe8906ei0",
  },
  {
    id: "e423a9ffc707c2a69f6cc2bb01b6cc1f58bbda5bcb647e0538815a86ae8aa756i0",
  },
  {
    id: "67585b141f31ffa2b27e7ea8e8b408ccba7c9ff0ce638333349e21021083b83di0",
  },
  {
    id: "516222329ca757964446c7a3efa51589e8276d78f573799ca9453d423f6cfbc5i0",
  },
  {
    id: "5c9b973380733fe42d3e57683af450939148d93804c42bbbd27f5ed42b019601i0",
  },
  {
    id: "64b50d011b6dc42b7299f7e2cb0ad0dc8b1c8cf357888569934175963cf364cai0",
  },
  {
    id: "b70a41dacf2ac26e58d1f3c03a0de0b68641700e5d34ccc5d58734b957e7deabi0",
  },
  {
    id: "361fd7f82849887ed888d3aabcb8dcca47a6650c2c0507f98d2ee703aec09d15i0",
  },
  {
    id: "0cd6f320a9d0770e04aa52a82eb82b81529c04f5af7add78cbda37f9d31a8ee9i0",
  },
  {
    id: "5b3d4c3233608ddb71b52bf159c98fcd8abe347515f475b7ebbe2ea0b50f9e2ci0",
  },
  {
    id: "f81da5fd305023f7756809915ccfcf72ef7df001e700f01a05f05d2f1629e3c1i0",
  },
  {
    id: "b2bda281386301c3259d2e95ffc841513f7d88deb1cc30daee38cf4a02500265i0",
  },
  {
    id: "976e50d49cc57a65610af7775b4aa887c128cca0dee6abba68152318ff91a9dai0",
  },
  {
    id: "cc804925c2db359a3fdc9bcd7276cd7702b4c2cdcfe23da88514af36dddff1e5i0",
  },
  {
    id: "66392a50fc3b551f2e05cdeca012a9b547bfe289208cd079f8ae12dc3ba049dbi0",
  },
  {
    id: "b4471a7e1d8dfb526dca42ab454effdfb0ec52dc64915af49876c87671ff94f4i0",
  },
  {
    id: "8d7af85a436e7f06d896109b43fedb85f5a9ab29e1af4a347e11b09d4c3cc011i0",
  },
  {
    id: "c2cb8aabda5d715f6ae9c9d842c36e65873624829e6718320864cd17468a1270i0",
  },
  {
    id: "ef2387d27a29f9aa4c4c26b0447288e6acfa864266f6b751408d8b0fba6d006fi0",
  },
  {
    id: "e36d33d06ecde087d92265e2a3f64516b9b16b5a7b4314b61cc8c29bfd403fdai0",
  },
  {
    id: "6b0413207f799fde5164177a09c7e152c761c1308e7de9bc32ad4e0a4a59e331i0",
  },
  {
    id: "3b8c5fc21c52355e25350c9525d031e6d60a8b71705238e4501529f71033c2d3i0",
  },
  {
    id: "97b7c9097ac7511ccdd994c64bcdc7e0a146a1dbc68b20177f7eb13b013a41bdi0",
  },
  {
    id: "bea50c0648891559d13b763cc19788e2a73cd09e2470fb640e7d72cc6f48363fi0",
  },
  {
    id: "7ec38b99e9d64be857a61f59aa87987a8356285f6ec530c68c3f42500bb127cei0",
  },
  {
    id: "3d791c0493d21c2333b012257de9cc34a9ccee926d3861c94f821a357fd91276i0",
  },
  {
    id: "3e95bd2f0efd83b4e896c1fc7f5af36d0503e4ea3f5b01cc17617be25592849di0",
  },
  {
    id: "fbac582e4c745f0a7d63a542877cbcfe9495493cae2920fc3ce8d906487065aci0",
  },
  {
    id: "a8874f37e3641c9530597bcfceafa68a6fc1a134e1bd78fddac23334d4f8f836i0",
  },
  {
    id: "6d56501723afc7e565b78a6ef71f38f1cd2d124daf02d664ba6eb28ce77d40fai0",
  },
  {
    id: "4149a664e69b917235061d7ff8246ae66b4dba229fe7e817a4169c5a8afce7ffi0",
  },
  {
    id: "3acf491329511ac0745ff6299ec664caca9147c34c21394233d079bd8ba09b68i0",
  },
  {
    id: "b1a1da927b7956dfd56cceb15dbc8f713a968ead387999a6d5a841529e734ec3i0",
  },
  {
    id: "4d30bcaf1398d143a4227e53d6240c347548bc4f0d970a539fb2abd8ae086b40i0",
  },
  {
    id: "3e17a395ac3a271f5c153a24a38d4ca2d026fce784e39cec75b18d2c9beda628i0",
  },
  {
    id: "f88ab0f214f51d722cef702e9f95e7e007e2a180548979411e8063d633306932i0",
  },
  {
    id: "3c7e95811b40b700e4e4baa720dbb264dc0caf6c56a0b5876a6b20ab16ec8c3ei0",
  },
  {
    id: "66bedef5dfe1bfdeada7a9f3be28e59c8ab02814b19c16c739464fdd5ca619edi0",
  },
  {
    id: "f01037475c53b5411659d4ce01369bec58009618e1b6ce0e1a0283a1cbde2e81i0",
  },
  {
    id: "21a2594e3ce5dda12f54dd13547d9f6fe4b75436e81de6f241c74e107e5d92c5i0",
  },
  {
    id: "26e83d81ce95a84a5dac9506aabe1b0b5a9abf2fb477d80567da70e38f1f1f76i0",
  },
  {
    id: "839c5878a3bed1a897b506808d117391221f308cef5b93c00a82c4795bb2d46di0",
  },
  {
    id: "86ecd8fc2967d990972782a9e6f8fdcd7bc51e207ddd4025187ad832ec0d2a3bi0",
  },
  {
    id: "02ec2a4e0d0eaa59fc3e337d3a00d3928cbb9ba3037391bf7209648098268422i0",
  },
  {
    id: "10e37d92628e2bcfd5fa188d69cf70b5e3c6dead7d9f08eb034302a69d255660i0",
  },
  {
    id: "c628b2efae2073e567d3f681eb1455c2efd8e3b33209a5d97f668c9d48668910i0",
  },
  {
    id: "4c65d651af7a80e0c1478e0d72ff65f60b1f600fb7587ecb12ca966961ae8ecbi0",
  },
  {
    id: "bf8f09d0c124e93946cff77acccd8e9b264015421aa9c306bb7013f72682b4adi0",
  },
  {
    id: "b527fb050093a94df4c0fb5de13776b9ee7d0ab91af47f65d853a5ccb4ba7a6ai0",
  },
  {
    id: "65cee8897e83d581aceb3fd0e5348aa38dec3439a32c2371471b9161d2ad60afi0",
  },
  {
    id: "48ce1616b22a9698a9e72bb1ec13029d030f1ba0e42fa25111db36faf53e6993i0",
  },
  {
    id: "739b9ff6b92962b5558c06b0cf18c79e6ef86660031dff319dfaa19c860f86a5i0",
  },
  {
    id: "d9ebc47f7c910d009fa406bad5df2cbc3280ec30031e75401cd134b6e640c7c8i0",
  },
  {
    id: "d37bb2f515bc8135cfe85207b0c67e9fcea24331100fa5695d6216fb974b54fei0",
  },
  {
    id: "e81cbce9be3444410b9a572e944a608941cf437d88c72c73f6fdba96e49781e4i0",
  },
  {
    id: "fe1c9aaf91142a1f90d3791eabfa86fede0916d2a4df6c1566550af422b05514i0",
  },
  {
    id: "6157028d428cff7909be6fe53c359e5e50f082b26b3d8cf197e06add28288e67i0",
  },
  {
    id: "fa017a0379cf9ace3200d2b719e0df8a348ed81af6834817f647b48d0951ad4ei0",
  },
  {
    id: "e8c50be92227044a20fc95e4bed25bbed8739b7c77ecb416a9213177796179a2i0",
  },
  {
    id: "eba41d7aeed79a01702b21c54cb57da0e5926bb35cb7d12dd7aafe8599fa4839i0",
  },
  {
    id: "9657a822563bbdc7917d7ebf3df87d45059371e5e1fe585fcd22bfead83a9b01i0",
  },
  {
    id: "aa7d50889db6dfd96abbce76540c5e5177d3645fb7fe4a5b3c486c113ccac45bi0",
  },
  {
    id: "27d4e68499cf3392eacf91f9558b2b95043a8c8779fc27db8e85a5a1f27a4111i0",
  },
  {
    id: "17bd74989c6a5d2755cce852045eb805ca8ca8b732340e41b45ef23345666d16i0",
  },
  {
    id: "0226cb5189dcf07dae338656eaa8783a795858739058e5c19008f0f642fe86fbi0",
  },
  {
    id: "150c4925baa311df65aaae762aa9100ef272ce566ace2bd2bef08f27c4322a3ei0",
  },
  {
    id: "b19257baf6deb00a0410ffb7960bb2c9dfad4247f18362552d9e371ce5715062i0",
  },
  {
    id: "347e0c411c97a17379ae8cb832aab86b1e11918747151f5a9b869a24e5b0d70fi0",
  },
  {
    id: "c0e140446a9e1a2d02feb6fcf5a2e1977803fe749d02512ac40bbfcaf40ac0b8i0",
  },
  {
    id: "b49eb54c1128101a071aa3980fe83aa356e46e2e4c68f83d51fae5a5e7cfe990i0",
  },
  {
    id: "83a73040bb9545fb0fafb44381e20e4a108c99ba6f6973652611d9c4e2b8e0e1i0",
  },
  {
    id: "b021c6067f42981567c240b42cd1ffcf238ede6dbd0991c25942982e33989724i0",
  },
  {
    id: "805ddca79aaf0eb89bd91b33b8880a54273d264ca3d90121c7b250e505de510ai0",
  },
  {
    id: "8358ffee3d791e112d2a73d5f0c49a1c4f2b0c6111b3107e11b6b58d5c5a52b3i0",
  },
  {
    id: "04b828280f9eb7530c3039a6ddaa654e8fc85d665594249ea057235f6af60031i0",
  },
  {
    id: "68462dcdacbb9e0e5475cb154198352dcbbc18b95660d62a61eaba6520be464ai0",
  },
  {
    id: "0bca58d35f6f0838afddada1b0c178092e1da2233f5e0f98b119da3222ca14cbi0",
  },
  {
    id: "e7b751355c1fba38910efae5c42becaaba072b92e74496c44ac79b64a326d6fci0",
  },
  {
    id: "c7c425a95e1bcce5be29341eb96ae73ecb12072fc05e23da3abff854782b0dd2i0",
  },
  {
    id: "e229fa9a85a5d27d8a3bb36460cc4b0b5a5e7f3a0bfb1f5b0931724e6d2a0fbei0",
  },
  {
    id: "176b047d0d85c479143745aeaafd18c496aebc6cdaa5d2678c5a935b6bf97418i0",
  },
  {
    id: "c963b2c0beda7f3fa0727a12b359e85410f270a2ab41820bb81358e86a0ad189i0",
  },
  {
    id: "3f1dada438c19730eab6d57a9f00cb63ddae693ddf5ced88b8c763ca25485a30i0",
  },
  {
    id: "083a2474961c4cb588d620c2674b30f3c8ccb76e7aa1c3a20e332042d0293371i0",
  },
  {
    id: "2d3ee87e1b749936e24e490d932fcc7064ab35ad2ee1af05ea1c0d0c1da9ba36i0",
  },
  {
    id: "a658a140baa575cbbc0ff17ed1665aa25ea1473624d27ae23a4d03ef5710d8eci0",
  },
  {
    id: "e5e556a4ae5c6d5da7711ac90a2750b80920abda4b36d3409f66d347c031f400i0",
  },
  {
    id: "2329007bd22266983f467b527110b1a2c089a6370ee39101d28b3a83b7e5965ci0",
  },
  {
    id: "b9239aa0aa42a17762296f9ffae7bfa952bd0f61aa095430d5247e110e033db2i0",
  },
  {
    id: "ccc85941998f5f456b36cfb9db978caea7b53f78b85e74c70b047509b392409ai0",
  },
  {
    id: "a690c68db21b0794684bb9c41e7c218373a762934dfe60c711ab56d12f2608f0i0",
  },
  {
    id: "70c1a7f8ca1fe7d57205f1b16cd8bf88a2d551aaf72e1081ffbbf72614d5aeb2i0",
  },
  {
    id: "f1482d9ed80bfe7872ef4070e09962ee25af894819e3e51e47381d052c9eeb54i0",
  },
  {
    id: "096650d0157c3601d8b82de9377e5d350ab8dadd880cfed77492f1086e4f028ci0",
  },
  {
    id: "5e8c5e6fc9864fc73d5aaa628566a11983ed7f34536ea87ace54b09a62ae6b5bi0",
  },
  {
    id: "ca4018341097880bc5f549c20440651586a6fbb29887567b1d54f8f847603752i0",
  },
  {
    id: "34b9de856f2c9996138b281be9811413d8b994f30a6b19ae4f5dd106cb0377e3i0",
  },
  {
    id: "19f43b2e370d342236f9fd7c0c367805c3255ed2c235fecffb31a5c4fc0c3135i0",
  },
  {
    id: "d58f67a59b8d086f8bc729463d2f0f37a47afc00536746e431fe481385a48ab8i0",
  },
  {
    id: "2563f46583f2425f1b771e32c87b6e4cfefdcf46c49be0af565e9c226fdc2b6ci0",
  },
];

export default dogeItems;
